import { render, staticRenderFns } from "./constructionResult.vue?vue&type=template&id=0d6170cf&scoped=true&"
import script from "./constructionResult.vue?vue&type=script&lang=js&"
export * from "./constructionResult.vue?vue&type=script&lang=js&"
import style0 from "./constructionResult.vue?vue&type=style&index=0&id=0d6170cf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d6170cf",
  null
  
)

export default component.exports