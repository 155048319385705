import http from '@/units/request'
import axios from 'axios'
export let constructionList = null
/** *
 * 获取施工协议列表
 * ***/
export const getconstructionList = (data) => {
  if (constructionList !== null && data['page.current'] === 1) {
    constructionList('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/is-agreement/construction/v1/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      constructionList = c
    })
  })
}
/** *
 * 获取施工协议详情
 * ***/
export const getconstructionInfo = (constructionId) => {
  return http({
    url: `/is-agreement/construction/v1/info/${constructionId}`,
    method: 'get'
  })
}
